// ViewProduct.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import db from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import '../styles.css';

function ViewProduct() {
  const { country, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [airRate, setAirRate] = useState(144);
  const [seaRate, setSeaRate] = useState(95);
  const [transitRate, setTransitRate] = useState(125);
  const [exchangeRate, setExchangeRate] = useState(30);

  useEffect(() => {
    const fetchRates = async () => {
      const ratesRef = doc(db, 'rates', country);
      const docSnap = await getDoc(ratesRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setAirRate(data.airRate || 144);
        if (country === 'korea') {
          setSeaRate(data.seaRate || 95);
          setTransitRate(data.transitRate || 125);
        }
        setExchangeRate(data.exchangeRate || (country === 'korea' ? 30 : 0.9));
      }
    };

    fetchRates();
  }, [country]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const docRef = doc(db, country === 'korea' ? 'koreanProducts' : 'thaiProducts', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProduct(docSnap.data());
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product: ", error);
      }
    };

    fetchProduct();
  }, [country, id]);

  if (!product) {
    return <p>找不到該商品</p>;
  }

  const {
    image,
    name,
    itemNumber,
    livePrice,
    onlinePrice,
    remarks,
    costKoreanWon,
    costTWD,
    costTHB,
    weight,
    styles,
    styleBarcodes,
    history
  } = product;

  const calculateExchangeCost = (cost) => {
    return cost / exchangeRate;
  };

  const calculateShippingCost = (rate) => {
    const cost = country === 'korea' ? costKoreanWon : costTHB;
    const exchangeCost = calculateExchangeCost(cost);
    const totalCost = weight * rate + (costTWD || exchangeCost);
    if (isNaN(totalCost)) {
      return null;
    }
    return Number(totalCost).toFixed(1);
  };

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  const renderHistoryEntry = (entry) => {
    const parts = [];
    parts.push(`日期: ${entry.date}`);
    if (entry.costKoreanWon) parts.push(`韓幣成本: ${entry.costKoreanWon}`);
    if (entry.costTWD) parts.push(`台幣成本: ${entry.costTWD}`);
    if (entry.costTHB) parts.push(`泰銖成本: ${entry.costTHB}`);
    if (entry.livePrice) parts.push(`直播價: ${entry.livePrice}`);
    if (entry.onlinePrice) parts.push(`線上價: ${entry.onlinePrice}`);
    if (entry.remarks) parts.push(`備註: ${entry.remarks}`);
    return parts.join(', ');
  };

  const renderCostInfo = () => {
    if (country === 'korea') {
      if (costKoreanWon) {
        return (
          <>
            <p>韓幣成本: {costKoreanWon}</p>
            <p>台幣換算成本: {Number(costKoreanWon / exchangeRate).toFixed(1)} TWD</p>
          </>
        );
      } else if (costTWD) {
        return <p>台幣成本: {costTWD}</p>;
      }
    } else if (country === 'thailand') {
      if (costTHB) {
        return (
          <>
            <p>泰銖成本: {costTHB}</p>
            <p>台幣換算成本: {Number(costTHB / exchangeRate).toFixed(1)} TWD</p>
          </>
        );
      } else if (costTWD) {
        return <p>台幣成本: {costTWD}</p>;
      }
    }
    return null;
  };

  const handleBack = () => {
    // 從 URL 查詢參數中獲取 search
    const params = new URLSearchParams(location.search);
    const search = params.get('search') || '';
    // 返回到 ProductList 並傳遞搜尋詞
    navigate(`/products/${country}?search=${encodeURIComponent(search)}`);
  };

  return (
    <div className="container product-view">
      <h2>商品詳情</h2>
      <div className="product-details">
        <img src={image} alt={name} className="product-image-large" />
        <div className="product-info">
          <p>名稱: {name}</p>
          <p>料號: {itemNumber}</p>
          <p>樣式: {styles.map((style, index) => `${style} (${styleBarcodes[index]})`).join(' / ')}</p>
          <p>🟥直播價: {livePrice}</p>
          <p>🟩線上價: {onlinePrice}</p>
          <p>備註: {remarks}</p>
          {renderCostInfo()}
          <p>重量: {weight}</p>
          <p>🚀直飛成本: {calculateShippingCost(airRate)}</p>
          {country === 'korea' && (
            <>
              <p>🚢海運成本: {calculateShippingCost(seaRate)}</p>
              <p>✈️轉機成本: {calculateShippingCost(transitRate)}</p>
            </>
          )}
          <button onClick={() => navigate(`/edit/${country}/${id}?search=${encodeURIComponent(new URLSearchParams(location.search).get('search') || '')}`)}>編輯</button>
          <button onClick={toggleHistory}>查看歷史價格</button>
          <button onClick={handleBack}>返回</button> {/* 新增的返回按鈕 */}
          {showHistory && (
            <div>
              <h3>歷史價格</h3>
              <ul>
                {history && history.length > 0 ? (
                  history.map((entry, index) => (
                    <li key={index}>{renderHistoryEntry(entry)}</li>
                  ))
                ) : (
                  <p>無歷史價格記錄</p>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
